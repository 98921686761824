let initialState = {
  isAuth: false,
};

const userReducer = (state = initialState, action) => {
  if (action.type === 'SET_AUTH') {
    return {
      ...state,
      isAuth: action.isAuth
    };
  }
  return state;
};

export default userReducer;