import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import "./BurgerMenu.css";

const BurgerMenu = () => (
  <Menu burgerBarClassName="v1" right className="menu" width="20%">
    <Link style={{ textDecoration: "none" }} to="/api">
      Licenses
    </Link>
    <Link style={{ textDecoration: "none" }} to="/users">
      Users
    </Link>
    <Link
      style={{ textDecoration: "none" }}
      to="/"
      onClick={() => localStorage.setItem("auth", false)}
    >
      Sign out
    </Link>
  </Menu>
);

export default BurgerMenu;
