import React from "react";
import Menu from "../components/BurgerMenu/BurgerMenu";
import MaterialTable from "material-table";
import axios from "axios";
import { isSafari, isMobileSafari } from "react-device-detect";
import moment from "moment";
import { AppConfig } from "../App";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: "License Code",
          field: "clientCode",
          initialEditValue: Math.random()
            .toString(36)
            .substr(2, 6),
        },
        { title: "Client Name", field: "clientName" },
        { title: "Division", field: "division" },
        {
          title: "Current Seats",
          field: "seatCount",
          type: "numeric",
          initialEditValue: 0,
          filtering: false,
          editable: false,
        },
        {
          title: "Maximum Seats",
          field: "seatsAllocated",
          type: "numeric",
          filtering: false,
        },
        {
          title: "Starting Date",
          field: "startDate",
          type: "date",
          filtering: false,
        },
        {
          title: "Expire Date",
          field: "endDate",
          type: "date",
          filtering: false,
        },
        {
          title: "Status",
          field: "status",
          lookup: { 0: "disabled", 1: "enabled" },
        },
      ],
      data: [],
      loading: true,
    };
  }

  generateRandomNr = () => {
    Math.random()
      .toString(36)
      .substr(2, 6);
  };

  componentDidMount() {
    axios({
      method: "get",
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      url: `${AppConfig.API_ROOT}/users/license/list`,
    })
      .then((response) => {
        this.setState({ loading: true });
        response.data.map((data) => {
          if (isSafari || isMobileSafari) {
            data.startDate = moment(data.startDate).format("MM/DD/YYYY");
            data.endDate = moment(data.endDate).format("MM/DD/YYYY");
          }
          this.setState((prevState) => ({
            data: [...prevState.data, data],
            loading: false,
          }));
          return null;
        });
      })
      .catch((e) => console.log(e));
  }

  render() {
    return (
      <div>
        <Menu />
        <div
          style={{
            paddingTop: "6%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            height: "100%",
          }}
        >
          <MaterialTable
            title="TEP License Management"
            columns={this.state.columns}
            data={this.state.data}
            isLoading={this.state.loading}
            style={{
              width: "90%",
            }}
            options={{
              pageSize: 20,
              pageSizeOptions: [10, 20, 30, 50, 100],
              addRowPosition: "first",
              exportButton: true,
              filtering: true,
              padding: "dense",
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  let unparsedData = {
                    startDate: newData.startDate
                      ? new Date(newData.startDate).toISOString()
                      : null,
                    endDate: newData.startDate
                      ? new Date(newData.endDate).toISOString()
                      : null,
                    seatCount: parseInt(newData.seatCount),
                    seatsAllocated: parseInt(newData.seatsAllocated),
                    status: parseInt(newData.status),
                  };

                  newData.adminId = 1;
                  newData.seatCount = unparsedData.seatCount;
                  newData.seatsAllocated = unparsedData.seatsAllocated;
                  newData.status = unparsedData.status;
                  newData.endDate = unparsedData.endDate;
                  newData.startDate = unparsedData.startDate;
                  console.log(newData);

                  axios({
                    method: "post",
                    withCredentials: true,
                    credentials: "include",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    url: `${AppConfig.API_ROOT}/users/license/add`,
                    data: newData,
                  })
                    .then((response) => {
                      this.setState((prevState) => {
                        const data = [...prevState.data];
                        console.log(JSON.stringify(newData, null, 2));
                        data.push(newData);
                        return { ...prevState, data };
                      });
                    })
                    .catch((e) => {
                      console.log(e.message);
                      reject();
                    });
                  resolve();
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  if (oldData) {
                    let unparsedData = {
                      startDate: newData.startDate
                        ? new Date(newData.startDate).toISOString()
                        : null,
                      endDate: newData.startDate
                        ? new Date(newData.endDate).toISOString()
                        : null,
                      seatCount: parseInt(newData.seatCount),
                      seatsAllocated: parseInt(newData.seatsAllocated),
                      status: parseInt(newData.status),
                      licenseId: parseInt(newData.licenceId),
                      adminId: 1,
                      division: newData.division,
                      clientCode: newData.clientCode,
                      clientName: newData.clientName,
                      clientId: newData.clientId,
                    };

                    console.log(newData);
                    axios({
                      method: "post",
                      withCredentials: true,
                      credentials: "include",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      url: `${AppConfig.API_ROOT}/users/license/update`,
                      data: unparsedData,
                    })
                      .then(() => {
                        this.setState((prevState) => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      })
                      .catch((e) => {
                        console.log(e.message);
                        reject();
                      });
                  }
                  resolve();
                }),
            }}
          />
        </div>
      </div>
    );
  }
}
export default Dashboard;
