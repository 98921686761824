import MaterialTable from "material-table";
import React from "react";
import axios from "axios";
import { AppConfig } from "../App";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import BurgerMenu from "../components/BurgerMenu/BurgerMenu";

export default class Users extends React.Component {
  API_ROOT;

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Client Company", field: "clientName" },
        { title: "Division", field: "division" },
        { title: "Email", field: "email" },
        {
          title: "No. of Completed Rituals",
          field: "completedRituals",
          type: "numeric",
          filtering: false,
        },
        {
          title: "No. of Completed Surveys",
          field: "completedSurveys",
          type: "numeric",
          filtering: false,
        },
        {
          title: "First Ritual Date",
          field: "firstRitualDate",
          filtering: false,
          type: "date",
        },
        {
          title: "Last Ritual Date",
          field: "lastRitualDate",
          filtering: false,
          type: "date",
        },
        {
          title: "First Survey Date",
          field: "firstSurveyDate",
          filtering: false,
          type: "date",
        },
        {
          title: "Last Survey Date",
          field: "lastSurveyDate",
          filtering: false,
          type: "date",
        },
        {
          title: "Second Last Ritual Score",
          field: "preLastRitualScore",
          filtering: false,
          type: "numeric",
        },
        {
          title: "Last Ritual Score",
          field: "lastRitualScore",
          filtering: false,
          type: "numeric",
        },
        {
          title: "Score Difference",
          field: "scoreDifference",
          filtering: false,
        },
      ],
      data: [],
      loading: true,
      errorMessage: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const fetchURL = (url) => axios.get(url),
      basicUserDetails = `${AppConfig.API_ROOT}/users/license/licenseMatchingUser`,
      promiseArray = [basicUserDetails].map(fetchURL);

    Promise.all(promiseArray)
      .then((data) => {
        let parsedData = [...data[0].data];

        parsedData.forEach((element) => {
          element.scoreDifference = element.preLastRitualScore
            ? `${element.preLastRitualScore - element.lastRitualScore}%`
            : null;
        });

        this.setState({ data: parsedData, loading: false });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ errorMessage: true, loading: false });
      });
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return 0;
    }
  };

  render() {
    const errorMessage = this.state;
    return (
      <>
        {errorMessage === true ? (
          <Snackbar
            open={errorMessage}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            <Alert onClose={this.handleClose} severity="error">
              Something went wrong, please try again later.
            </Alert>
          </Snackbar>
        ) : (
          <>
            <BurgerMenu />
            <div
              style={{
                paddingTop: "6%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                height: "100%",
              }}
            >
              <MaterialTable
                title="TEP User Report"
                columns={this.state.columns}
                data={this.state.data}
                isLoading={this.state.loading}
                style={{
                  width: "90%",
                }}
                options={{
                  pageSize: 20,
                  pageSizeOptions: [20, 30, 40, 50, 100, 500, 1000],
                  addRowPosition: "first",
                  exportButton: true,
                  filtering: true,
                  grouping: true,
                  padding: "dense",
                  exportFileName: `${new Date()} Tep User Raport`,
                }}
              />
            </div>
          </>
        )}
      </>
    );
  }
}
