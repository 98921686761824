import React from "react";
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as _appConfig from "./api.json";
import Users from "./pages/Users";
import { ProtectedView } from "./components/ProtectedView";
export const AppConfig = _appConfig.prod;

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Auth />
        </Route>
        <ProtectedView exact path="/api">
          <Dashboard />
        </ProtectedView>
        <ProtectedView exact path="/users">
          <Users />
        </ProtectedView>
      </Switch>
    </Router>
  );
}

export default App;
