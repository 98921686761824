import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import axios from "axios";
import { AppConfig } from "../App";
import { useHistory } from "react-router-dom";

const AuthForm = () => {
  let history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("This field is required"),
      password: Yup.string()
        .min(8, "Wrong password format \n (8 char, 1 special, 1 uppercase)")
        .required("This field is required"),
    }),
    onSubmit: (values) => {
      axios({
        method: "post",
        withCredentials: true,
        credentials: "include",
        url: `${AppConfig.API_ROOT}/users/auth/adminlogin`,
        data: {
          email: values.email.toLowerCase(),
          password: values.password,
          timer: "3600",
          token: "TEP123",
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.log("header is:", res.headers);
          console.log("Response:", res);
          if (res.data === true) {
            localStorage.setItem("auth", true);
            // setAuth(true);
            history.replace("/api");
          }
        })
        .catch((e) => console.log(e));
    },
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          paddingTop: "15%",
          marginBottom: "1.5%",
        }}
      >
        <p style={{ fontSize: "3.2rem" }}>Please sign in</p>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          marginTop: "1%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          style={{ marginBottom: "1rem", width: "17rem" }}
          id="outlined-password-input"
          label="E-mail"
          type="email"
          name="email"
          autoComplete="current-email"
          variant="outlined"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          helperText={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
        />
        <TextField
          style={{ marginBottom: "1rem", width: "17rem" }}
          id="outlined-password-input"
          label="Password"
          type="password"
          name="password"
          autoComplete="current-password"
          variant="outlined"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          helperText={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : null
          }
        />
        <Button variant="outlined" color="primary" type="submit">
          Submit
        </Button>
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setAuth: (isAuth) => {
    dispatch({
      type: "SET_AUTH",
      isAuth,
    });
  },
});

const mapStateToProps = (state) => ({
  isAuth: state.userReducer.isAuth,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);
