/* eslint-disable import/no-mutable-exports */
/* eslint-disable prefer-const */
import { combineReducers } from 'redux';
import userReducer from './userReducer';


let rootReducer = combineReducers({
  userReducer,
});


export default rootReducer;
